<template>
  <div class="row">
    <div class="flex xs12">
      <p>{{ label }}</p>
    </div>
    <div class="flex xs12 md6">
      <va-input
        step="1"
        type="number"
        color="info"
        label="X"
        v-model="coordinate.x"
        :id="`x-${id}-input`"
        :name="`x${id}Input`"
        :disabled="loading"
      />
    </div>
    <div class="flex xs12 md6">
      <va-input
        step="1"
        type="number"
        color="info"
        label="Y"
        v-model="coordinate.y"
        :id="`y-${id}-input`"
        :name="`y${id}Input`"
        :disabled="loading"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'coordinates',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    coordinate: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      id: Math.random() * 100,
    }
  },
}
</script>
